// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.soldHeader input {
    padding: 12px 8px;
}
.soldHeader label{
    font-size: 12px;
}
.soldHeaderSearch input::placeholder{
    font-size: 12px;
}
.soldHeaderSearch input {
  padding: 12px 8px;
}
.soldHeader .MuiInputLabel-shrink {
  font-size: 12px;
} 
.soldHeaderDropdown fieldset {
   padding: 7px 12px;
   /* margin-bottom: 12px; */
   /* margin-top: 12px; */
}
.soldHeaderDropdown legend {
    font-size: 12px;
 }
 .soldHeaderDropdown label {
    margin-right: 50px;
 }`, "",{"version":3,"sources":["webpack://./src/Components/contributers/ContributorSoldImagesHistory/contributor.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;AACjB;AACA;GACG,iBAAiB;GACjB,yBAAyB;GACzB,sBAAsB;AACzB;AACA;IACI,eAAe;CAClB;CACA;IACG,kBAAkB;CACrB","sourcesContent":[".soldHeader input {\n    padding: 12px 8px;\n}\n.soldHeader label{\n    font-size: 12px;\n}\n.soldHeaderSearch input::placeholder{\n    font-size: 12px;\n}\n.soldHeaderSearch input {\n  padding: 12px 8px;\n}\n.soldHeader .MuiInputLabel-shrink {\n  font-size: 12px;\n} \n.soldHeaderDropdown fieldset {\n   padding: 7px 12px;\n   /* margin-bottom: 12px; */\n   /* margin-top: 12px; */\n}\n.soldHeaderDropdown legend {\n    font-size: 12px;\n }\n .soldHeaderDropdown label {\n    margin-right: 50px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
