// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password-div {
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 50%;
  padding: 30px 50px 40px 50px;
}

.change-password-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 32px;
}

.change-password-submit {
  height: 67px;
  width: 100%;
  border: 0;
  outline: none;
  background-color: black;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  letter-spacing: 0.5px !important;
  margin-top: 15px;
}

@media only screen and (min-width: 250px) and (max-width: 670px) {
  .change-password-div {
    width: auto;
    padding: 40px 30px 45px 30px;
  }

  .change-password-label {
    margin-left: 3px;
    font-size: 15px;
  }

  .change-password-submit {
    height: 50px;
    font-size: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Assets/css/changepassword.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,4CAA4C;EAC5C,mBAAmB;EACnB,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;IACX,4BAA4B;EAC9B;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;AACF","sourcesContent":[".change-password-div {\n  background-color: #ffffff;\n  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n  border-radius: 20px;\n  width: 50%;\n  padding: 30px 50px 40px 50px;\n}\n\n.change-password-label {\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-weight: 600;\n  font-size: 18px;\n  margin-top: 20px;\n  margin-left: 32px;\n}\n\n.change-password-submit {\n  height: 67px;\n  width: 100%;\n  border: 0;\n  outline: none;\n  background-color: black;\n  color: white;\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-size: 17px;\n  font-weight: 600;\n  border-radius: 10px;\n  letter-spacing: 0.5px !important;\n  margin-top: 15px;\n}\n\n@media only screen and (min-width: 250px) and (max-width: 670px) {\n  .change-password-div {\n    width: auto;\n    padding: 40px 30px 45px 30px;\n  }\n\n  .change-password-label {\n    margin-left: 3px;\n    font-size: 15px;\n  }\n\n  .change-password-submit {\n    height: 50px;\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
