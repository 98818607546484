// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-forgotPass {
  text-align: center;
  margin-bottom: 29px !important;
}

.forgot-button-main {
  margin-top: 25px;
}

.forgotButton-button-main {
  margin-top: 25px;
}

.forgotButton-button-step2 {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Assets/css/forgotPass.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".title-forgotPass {\n  text-align: center;\n  margin-bottom: 29px !important;\n}\n\n.forgot-button-main {\n  margin-top: 25px;\n}\n\n.forgotButton-button-main {\n  margin-top: 25px;\n}\n\n.forgotButton-button-step2 {\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
