// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-bazaar-div {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: 35px;
}

.video-bazaar-wid {
padding: 10px;
}



.video-measure{
    width:100%;
height: 358px;
}

.rh5v-Overlay_inner {
  background-color: rgba(255, 255, 255, 0.99) !important;
  border-radius: 50% !important;
}

.rh5v-Overlay_inner svg path {
  fill: rgba(0, 0, 0, 0.4);
}

.pagination-from-top{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

@media only screen and (min-width: 900px) and (max-width: 1100px) {
  .video-bazaar-div {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .video-bazaar-div {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.video-measure{
  width:100%;
height: 260px;
}
}


@media only screen and (min-width: 250px) and (max-width: 599px) {
  .video-bazaar-div {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.video-measure{
  width:100%;
height: 200px;
}
.pagination-custom ul li button{
  font-size: 10px;
  height: 28px;
  min-width: 22px;
  max-width: 28px;
  
  }

}`, "",{"version":3,"sources":["webpack://./src/Components/Assets/css/video.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gDAAgD;EAClD,gBAAgB;AAClB;;AAEA;AACA,aAAa;AACb;;;;AAIA;IACI,UAAU;AACd,aAAa;AACb;;AAEA;EACE,sDAAsD;EACtD,6BAA6B;AAC/B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;EACE;IACE,gDAAgD;AACpD;AACA;;AAEA;EACE;IACE,gDAAgD;AACpD;;AAEA;EACE,UAAU;AACZ,aAAa;AACb;AACA;;;AAGA;EACE;IACE,gDAAgD;AACpD;;AAEA;EACE,UAAU;AACZ,aAAa;AACb;AACA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,eAAe;;EAEf;;AAEF","sourcesContent":[".video-bazaar-div {\n    display: grid;\n    grid-template-columns: repeat(3, minmax(0, 1fr));\n  margin-top: 35px;\n}\n\n.video-bazaar-wid {\npadding: 10px;\n}\n\n\n\n.video-measure{\n    width:100%;\nheight: 358px;\n}\n\n.rh5v-Overlay_inner {\n  background-color: rgba(255, 255, 255, 0.99) !important;\n  border-radius: 50% !important;\n}\n\n.rh5v-Overlay_inner svg path {\n  fill: rgba(0, 0, 0, 0.4);\n}\n\n.pagination-from-top{\n    display: flex;\n    justify-content: center;\n    margin-top: 50px;\n}\n\n@media only screen and (min-width: 900px) and (max-width: 1100px) {\n  .video-bazaar-div {\n    grid-template-columns: repeat(2, minmax(0, 1fr));\n}\n}\n\n@media only screen and (min-width: 600px) and (max-width: 900px) {\n  .video-bazaar-div {\n    grid-template-columns: repeat(2, minmax(0, 1fr));\n}\n\n.video-measure{\n  width:100%;\nheight: 260px;\n}\n}\n\n\n@media only screen and (min-width: 250px) and (max-width: 599px) {\n  .video-bazaar-div {\n    grid-template-columns: repeat(1, minmax(0, 1fr));\n}\n\n.video-measure{\n  width:100%;\nheight: 200px;\n}\n.pagination-custom ul li button{\n  font-size: 10px;\n  height: 28px;\n  min-width: 22px;\n  max-width: 28px;\n  \n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
